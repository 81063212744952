/* eslint-disable no-console */
/* eslint-disable func-names */
import { useApiService } from '../apis/api-service';

const IGNORED_LOGS = [
  /AbortError: Fetch is aborted/,
  /TypeError: t.layout is undefined/,
  /TypeError: undefined is not an object \(evaluating 't.layout.get'\)/,
  /TypeError: Cannot read properties of undefined \(reading 'getOwnLayer'\)/,
];

export const useApiLogger = () => {
  const apiService = useApiService();

  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: (e: Error) => {
      const isLocal = process.env.NODE_ENV === 'development';
      if (!isLocal) {
        const message = String(e);
        if (IGNORED_LOGS.some((ignored) => ignored.test(message))) {
          return;
        }
        apiService
          .postFrontendLogs({
            level: 'error',
            message,
            details: {
              page: window.location.pathname,
              exception:
                e?.stack ?? 'No stacktrace available, perhaps the error was not an Error object?',
            },
          })
          .catch(() => undefined); // Intentionally empty catch block. This avoids an infinite loop of errors.
      }
    },
  };
};
