import { ImageryLayer } from '../../apis/generated';
import { InfoBarTabs } from '../../components/map/customer_map_logic/polygon_editor/InfoBar';
import { GetRequestedPhotoPointsResponse } from '../../models/PhotoPoints';

export interface ContextState {
  mapLoaded: boolean;
  selectedFeatureIds: Array<string | number>;
  selectedPhotoKey?: string | undefined;
  showPhotos?: boolean;
  errors: Error[];
  tabKey?: InfoBarTabs;
  currentBaseMap?: ImageryLayer;
  requestedPhotoPoints?: GetRequestedPhotoPointsResponse;
  imageryHistory?: ImageryLayer[];
  imageryIdx?: number;
}

export const emptyContextState: ContextState = {
  errors: [],
  selectedFeatureIds: [],
  mapLoaded: false,
  showPhotos: false,
};
