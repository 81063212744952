import { useApiService } from '../../apis/api-service';
import { ApiError } from '../../apis/generated';
import { useStore } from './sources/useStore';
import { useCurrentCustomerIdMaybe } from './useCustomerIdMaybe';

export const useCustomerAccess = () => {
  const customerId = useCurrentCustomerIdMaybe();
  const apiService = useApiService();

  const { data, state } = useStore({
    key: `/v1/customers/${customerId}/access`,
    getFromServer: async () => {
      try {
        if (customerId === undefined) {
          return undefined;
        }
        return await apiService.getCustomerAccess(customerId);
      } catch (error) {
        const isForbidden = error instanceof ApiError && error.status === 403;
        if (isForbidden) {
          return {
            available_features: [],
          };
        }
        throw error;
      }
    },
  });

  return {
    access: data,
    state,
  };
};
