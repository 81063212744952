import { useApiService } from '../apis/api-service';
import { useStore } from '../contexts/remote-data/sources/useStore';

export const useCompanyUsers = (company_id: number | undefined) => {
  const apiService = useApiService();

  const { data, state, error } = useStore({
    key: `/api/v1/companies/${company_id}/users`,
    getFromServer: async () => (company_id ? apiService.getCompanyUsers(company_id) : []),
  });

  return {
    users: data,
    state,
    error,
  };
};
