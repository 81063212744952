/* eslint-disable camelcase */
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import '../theme/carboncrop.scss';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Circled } from '../components/Circled';
import CardLink, { CardLinkParams } from './CardLink';

interface ReportCardParams extends CardLinkParams {
  title: React.ReactNode;
  description: React.ReactNode;
  icon: IconDefinition | IconProp;
}

const ReportCard: React.FC<ReportCardParams> = ({ title, description, icon, href, onClick }) => {
  return (
    <CardLink href={href} onClick={onClick}>
      <Circled
        size="5rem"
        className="
          p-4
          m-3
        "
      >
        <FontAwesomeIcon size="2x" icon={icon} color="gray" />
      </Circled>
      <div className="flex-column flex-grow-1">
        <h5 className="text-start">{title}</h5>
        <div className="text-start text-muted">{description}</div>
      </div>
    </CardLink>
  );
};

export default ReportCard;
