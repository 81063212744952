import _ from 'lodash';
import { parse, ParseResult, unparse } from 'papaparse';
import React from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import { CarbonPrices, NZUPrice } from '../apis/generated';
import { MiniDragAndDrop } from '../components/MiniDragAndDrop';
import { UnknownCsv } from '../contexts/CustomSpecies';
import { useCarbonCropNZUForecast } from '../contexts/remote-data/useCarbonCropNZUForecast';
import { useCompanyProperties } from '../contexts/remote-data/useCompanyProperties';
import { useCurrentCompanyId } from '../contexts/remote-data/useCurrentCompanyId';
import { useUserMe } from '../contexts/remote-data/useUserMe';
import { formatDateAndTime, formatNum } from '../formatters/formatters';
import { useCarbonCropCCUForecast } from '../contexts/remote-data/useCarbonCropCCUForecast';

const TEMPLATE_NZU_PRICE_DATA: NZUPriceData = {
  start_year: new Date().getFullYear(),
  values: [
    50, 58, 65, 71, 76, 80, 83, 86, 88, 90, 92, 93, 94, 95, 96, 97, 97, 98, 98, 98, 99, 99, 99, 99,
    99, 99, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
  ],
};

interface NZUPriceData {
  start_year: number;
  values: Array<number>;
}

const customSpeciesHeaders = ['Year', 'Price'];

export const customNZUPriceCSVToData = (csv: string): NZUPriceData => {
  const res: ParseResult<UnknownCsv> = parse(csv, {
    header: true,
  });
  if (res.errors.length > 0) {
    throw new Error(`${res.errors[0].message} on line: ${res.errors[0].row}`);
  }

  if (!_.isEqual(res.meta.fields, customSpeciesHeaders)) {
    const missingFields = _.difference(customSpeciesHeaders, res.meta.fields ?? []);
    const extraFields = _.difference(res.meta.fields, customSpeciesHeaders);
    throw new Error(
      `The provided spreadsheet does not follow the expected template. ${
        missingFields.length > 0 ? `Missing Fields: ${missingFields.toString()}` : ''
      } ${extraFields.length > 0 ? `Extra Fields: ${extraFields.toString()}` : ''}`,
    );
  }

  if (res.data.length === 0) {
    throw new Error('File is empty');
  }
  if (res.data.length > 100) {
    throw new Error('File has more than 100 rows, this is unexpected');
  }

  const firstYear = Number(res.data[0].Year);
  if (firstYear > new Date().getFullYear()) {
    throw new Error(
      `Price forecast year must start in ${new Date().getFullYear()}, but it starts in ${firstYear}`,
    );
  }

  let previousYear = firstYear - 1;

  const values = res.data.map((row): number => {
    const year = Number(row.Year);
    if (previousYear !== year - 1) {
      throw new Error(`Years must be consecutive, but ${year} is following ${previousYear}`);
    }
    previousYear = year;
    if (Number(row.Price) < 0) {
      throw new Error(`Year ${year} contains negative value`);
    }
    return Number(row.Price);
  });

  const numberOfYears = previousYear + 1 - firstYear;

  if (numberOfYears < 35) {
    throw new Error(`Expected at least 35 year values, got ${numberOfYears}`);
  }

  return {
    start_year: firstYear,
    values: values,
  };
};

export const customNZUPriceToCSV = (data: NZUPriceData): string => {
  return unparse(
    data.values
      .map((val, idx) => {
        return {
          Year: idx + data.start_year,
          Price: val,
        };
      })
      .flat(),
  );
};

export const downloadFileFromNZUPrice = (nzuPriceData: NZUPriceData, filename: string) => {
  const blob = new Blob([customNZUPriceToCSV(nzuPriceData)], {
    type: 'text/csv',
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();
};

export const nzuForecastToNZUPriceData = (carbonPrice: CarbonPrices): NZUPriceData => {
  const first_year = Number(Object.keys(carbonPrice.expected)[0]);
  return {
    start_year: first_year,
    values: Object.values(carbonPrice.expected),
  };
};

export const useNZUPrice = () => {
  const companyId = useCurrentCompanyId();
  const { companyProperties } = useCompanyProperties(companyId);

  const { carbonCropNZUForecast } = useCarbonCropNZUForecast();

  return {
    nzuPrice:
      companyProperties?.nzu_price && companyProperties.nzu_price.enabled
        ? companyProperties.nzu_price
        : carbonCropNZUForecast && nzuForecastToNZUPriceData(carbonCropNZUForecast),
  };
};

export const useCCUPrice = () => {
  const { carbonCropCCUForecast } = useCarbonCropCCUForecast();
  return {
    ccuPrice: carbonCropCCUForecast && nzuForecastToNZUPriceData(carbonCropCCUForecast),
  };
};

export const NZUCustomPrice: React.FC = () => {
  const currentCompanyId = useCurrentCompanyId();
  const { companyProperties, setCompanyProperties } = useCompanyProperties(currentCompanyId);
  const { userMe } = useUserMe();

  const setNZUPrice = (newNzuPrice: NZUPrice) =>
    setCompanyProperties((prop) => {
      if (!prop) {
        return prop;
      }
      return {
        ...prop,
        nzu_price: newNzuPrice,
      };
    });

  const uploadFile = async (file: File) => {
    const dataAsText = await file.text();
    const splitFilename = file.name.toLowerCase().split('.');
    if (splitFilename[splitFilename.length - 1] !== 'csv') {
      throw Error(`File must be of CSV type`);
    }
    const nzuPriceData = customNZUPriceCSVToData(dataAsText);

    setNZUPrice({
      enabled: true,
      file_name: file.name,
      date_uploaded: new Date().toUTCString(),
      start_year: nzuPriceData.start_year,
      values: nzuPriceData.values,
      uploaded_by: { email: userMe?.email },
    });
  };

  const downloadFile = () => {
    if (companyProperties?.nzu_price) {
      downloadFileFromNZUPrice(
        companyProperties?.nzu_price,
        companyProperties?.nzu_price.file_name,
      );
    }
  };

  const downloadTemplate = () => {
    downloadFileFromNZUPrice(TEMPLATE_NZU_PRICE_DATA, 'CarbonCrop-custom-nzu-price-template.csv');
  };

  const { nzuPrice } = useNZUPrice();

  const onChangeCustomEnabled = (
    e: any /* eslint-disable-line @typescript-eslint/no-explicit-any */,
  ) => {
    if (companyProperties === undefined || companyProperties.nzu_price === undefined) {
      return;
    }
    setNZUPrice({
      ...companyProperties.nzu_price,
      enabled: e.target.value === 'custom',
    });
  };

  const uploadDateLabel = formatDateAndTime(companyProperties?.nzu_price?.date_uploaded);

  return (
    <div>
      <p>NZU price is used to convert between NZUs and dollars.</p>
      <p>Your NZU price forecast is:</p>
      <div className="border border-1  my-4">
        <div className="overflow-x-scroll">
          {nzuPrice && (
            <Table bordered size="sm" style={{ backgroundColor: 'white' }}>
              <thead>
                <tr>
                  {nzuPrice.values.map((val, index) => {
                    return <th className="bg-transparent px-2">{nzuPrice.start_year + index}</th>;
                  })}
                </tr>
              </thead>
              <tbody className="bg-transparent">
                <tr className="bg-transparent">
                  {nzuPrice.values.map((val) => {
                    return <td className="bg-transparent px-2">{'$'.concat(formatNum(val, 2))}</td>;
                  })}
                </tr>
              </tbody>
            </Table>
          )}
        </div>
      </div>

      <p>
        Most of our users use CarbonCrop&apos;s default forecast. Our forecasts generally use a
        lower price than the official OECD estimate as we would rather underestimate than
        overpromise! Carbon markets are inherently uncertain, prices are not guaranteed and may
        differ from our predictions. We review and adjust our forecast each quarter.
      </p>
      <p>
        You may choose to use your own forecasting model. Custom tables must adhere to our format.{' '}
        <Button onClick={downloadTemplate} variant="link-pink" className="p-0 m-0">
          Click here to download a template
        </Button>
      </p>
      <div className="px-4" style={{ paddingBottom: '1em' }}>
        <Form.Check
          style={{ paddingBottom: '0.5em' }}
          type="radio"
          value="carboncrop"
          id="setUseCarbonCropPriceYes"
          checked={!companyProperties?.nzu_price?.enabled}
          name="group1"
          label="Use CarbonCrop’s default NZU forecast table"
          onChange={onChangeCustomEnabled}
        />
        <Form.Check
          type="radio"
          disabled={
            companyProperties?.nzu_price === undefined || companyProperties?.nzu_price === null
          }
          name="group1"
          value="custom"
          checked={companyProperties?.nzu_price?.enabled}
          id="setUseCarbonCropPriceNo"
          onChange={onChangeCustomEnabled}
          label="Use custom forecast"
        />
      </div>
      <MiniDragAndDrop uploadFile={uploadFile}>
        <>
          {companyProperties?.nzu_price ? (
            <>
              <div className="d-flex flex-column align-items-center flex-grow-1">
                <div>{companyProperties.nzu_price.file_name}</div>
                <div className="fst-italic">{`Uploaded by ${companyProperties.nzu_price.uploaded_by.email}, on ${uploadDateLabel}`}</div>
              </div>

              <Button
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  return downloadFile();
                }}
              >
                Download
              </Button>
            </>
          ) : (
            <div>Drag and drop your file here</div>
          )}
        </>
      </MiniDragAndDrop>
    </div>
  );
};
