import { CommonGeoJsonProperties, PolygonStatusType, SegmentFeature } from './Segments';

export interface PolygonStatusDataType {
  val: PolygonStatusType;

  // Human readable name to display for this status
  name: string;

  permissions: PermissionType;
}

export interface PermissionType {
  read: boolean;
  write: boolean;
  delete: boolean;
  change_status: boolean;
}

export const POLYGON_STATUS_DATA: PolygonStatusDataType[] = [
  {
    val: 'draft',
    name: 'Draft',
    permissions: {
      read: true,
      write: true,
      delete: true,
      change_status: true,
    },
  },
  {
    val: 'registered',
    name: 'Registered',
    permissions: {
      read: true,
      write: false,
      delete: false,
      change_status: false,
    },
  },
  {
    val: 'rejected',
    name: 'Rejected',
    permissions: {
      read: true,
      write: false,
      delete: true,
      change_status: true,
    },
  },
  {
    val: 'submitted',
    name: 'Submitted',
    permissions: {
      read: true,
      write: true,
      delete: false,
      change_status: true,
    },
  },
];

export const ACCOUNT_MANAGER_PERMITTED_TRANSITIONS: {
  [key in PolygonStatusType]: PolygonStatusType[];
} = {
  draft: ['submitted', 'rejected'],
  submitted: ['rejected'],
  rejected: ['draft', 'submitted'],
  registered: [],
};

type statusMapType = { [key in PolygonStatusType]: PolygonStatusDataType };
export const STATUS_MAP: statusMapType = POLYGON_STATUS_DATA.reduce((obj, item) => {
  return {
    ...obj,
    [item.val]: item,
  };
}, {} as statusMapType);

/**
 * Returns a polygon status with default value of 'draft'
 *
 * @param status Optional polygon status
 * @returns The actual status
 */
export const getPolygonStatus = (status: PolygonStatusType | undefined): PolygonStatusType => {
  return status ?? 'draft';
};

/**
 * Returns True if a transition is possible to do. It does not guarantee that
 * said transition would actually succeed (as you may not have permission),
 * but only that it CAN be done.
 *
 * @param startStatus The status the polygon is current in
 * @param endStatus The status to which you wish to transition
 * @returns True if this transition is possible
 */
export const isStatusTransitionPermitted = (
  startStatus: PolygonStatusType,
  endStatus: PolygonStatusType,
) => ACCOUNT_MANAGER_PERMITTED_TRANSITIONS[startStatus].includes(endStatus);

export const permissionForStatus = (status: PolygonStatusType | undefined): PermissionType => {
  return STATUS_MAP[getPolygonStatus(status)].permissions;
};

export const isLocked = (properties: CommonGeoJsonProperties): boolean => {
  if (properties.locked === undefined) {
    return ['registered', 'rejected', 'submitted'].includes(properties.status || 'draft');
  }

  return properties.locked;
};

export const isEditable = (feature: SegmentFeature): boolean => {
  return !isLocked(feature.properties);
};
