import _ from 'lodash';

export const MULTIPLE_VALUES: unique symbol = Symbol('Multiple values');
export type T_MULTIPLE_VALUES = typeof MULTIPLE_VALUES;

export type MultiValue<A> = {
  [K in keyof A]: A[K] | T_MULTIPLE_VALUES;
};

export const mergeProperties = <T extends object>(properties: T[]): MultiValue<T> => {
  if (properties.length === 0) {
    throw new Error('Cannot merge empty properties');
  }
  const propertiesKeys = _.uniq(_.flatMap(properties.map((p) => Object.keys(p) as (keyof T)[])));

  return _.zipObject(
    propertiesKeys,
    propertiesKeys.map((key) => {
      const uniqueValues = _.uniq(properties.map((p) => p[key]));
      if (uniqueValues.length === 1) {
        return uniqueValues[0];
      }
      return MULTIPLE_VALUES;
    }),
  ) as MultiValue<T>;
};
