import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { grey_90 } from '../theme/colors';
import TaskCard from './TaskCard';

const NewTaskModal: React.FC<{
  showCreateTaskModal: boolean;
  setShowCreateTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ showCreateTaskModal, setShowCreateTaskModal }) => {
  return (
    <Modal
      show={showCreateTaskModal}
      onHide={() => setShowCreateTaskModal(false)}
      backdrop
      keyboard // Allows closing the modal with the "Escape" key
      centered
      size="lg"
    >
      <Modal.Header closeButton style={{ backgroundColor: grey_90 }}>
        <Modal.Title>Start something new</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: grey_90 }}>
        <div
          className="gap-4 justify-content-center"
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(20rem, 1fr))',
          }}
        >
          <TaskCard taskType="assess_ets_eligibility" />
          <TaskCard taskType="transfer_nzus" />
          <TaskCard taskType="create_ets_account" />
          <TaskCard taskType="transfer_ets_participation" />
          <TaskCard taskType="register_land_to_ets" />
          <TaskCard taskType="remove_land_from_ets" />
        </div>
      </Modal.Body>
      <Modal.Footer style={{ backgroundColor: grey_90 }}>
        <Button variant="secondary" onClick={() => setShowCreateTaskModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewTaskModal;
