import React from 'react';
import { ImageryLayer } from '../apis/generated';
import { InfoBarTabs } from '../components/map/customer_map_logic/polygon_editor/InfoBar';
import { GetRequestedPhotoPointsResponse } from '../models/PhotoPoints';
import { ContextState } from './customer-context-operations/ContextState';
import { selectFeatures } from './customer-context-operations/FeatureOperations';

export type ContextActions =
  | {
      type: 'SET_MAP_LOADED';
      payload: boolean;
    }
  | {
      type: 'ADD_ERROR';
      payload: Error;
    }
  | {
      type: 'REMOVE_ERROR';
      payload: Error;
    }
  | {
      type: 'SET_TAB_KEY';
      payload: InfoBarTabs | undefined;
    }
  | {
      type: 'SET_SELECTED_FEATURES';
      payload: Array<string | number>;
    }
  | {
      type: 'SET_PHOTO_VISIBILITY';
      payload: boolean;
    }
  | {
      type: 'SET_SELECTED_PHOTO';
      payload: string | undefined;
    }
  | {
      type: 'SET_REQUESTED_PHOTO_POINTS';
      payload: GetRequestedPhotoPointsResponse;
    }
  | {
      type: 'UNDO_IMAGERY_HISTORY';
      payload: undefined;
    }
  | {
      type: 'REDO_IMAGERY_HISTORY';
      payload: undefined;
    }
  | {
      type: 'SET_CURRENT_BASEMAP';
      payload: ImageryLayer;
    };

const customerContextReducer: React.Reducer<ContextState, ContextActions> = (
  state,
  action,
): ContextState => {
  switch (action.type) {
    case 'SET_MAP_LOADED':
      return { ...state, mapLoaded: action.payload };
    case 'ADD_ERROR':
      if (!state.errors.some((e) => e.message === action.payload.message)) {
        return { ...state, errors: [...state.errors, action.payload] };
      }
      return state;
    case 'REMOVE_ERROR':
      return {
        ...state,
        errors: state.errors.filter((e) => e.message !== action.payload.message),
      };
    case 'SET_TAB_KEY':
      return {
        ...state,
        tabKey: action.payload,
      };
    case 'SET_SELECTED_FEATURES':
      return {
        ...selectFeatures(state, action.payload),
      };
    case 'SET_PHOTO_VISIBILITY':
      return {
        ...state,
        showPhotos: action.payload,
      };
    case 'SET_SELECTED_PHOTO':
      return {
        ...state,
        selectedPhotoKey: action.payload,
      };
    case 'SET_CURRENT_BASEMAP':
      if (state.currentBaseMap !== action.payload) {
        if (state.imageryHistory?.length === 0) {
          return {
            ...state,
            currentBaseMap: action.payload,
            imageryHistory: [action.payload],
            imageryIdx: 0,
          };
        }
        return {
          ...state,
          currentBaseMap: action.payload,
          imageryHistory: [
            ...(state.imageryHistory || []).slice(
              0,
              state.imageryIdx !== undefined ? state.imageryIdx + 1 : 0,
            ),
            action.payload,
          ],
          imageryIdx: state.imageryIdx !== undefined ? state.imageryIdx + 1 : 0,
        };
      }
      return state;
    case 'SET_REQUESTED_PHOTO_POINTS':
      return {
        ...state,
        requestedPhotoPoints: action.payload,
      };
    case 'UNDO_IMAGERY_HISTORY':
      if (state.imageryHistory && state.imageryIdx !== undefined && state.imageryIdx > 0) {
        return {
          ...state,
          currentBaseMap: state.imageryHistory[state.imageryIdx - 1],
          imageryIdx: state.imageryIdx - 1,
        };
      }
      return state;
    case 'REDO_IMAGERY_HISTORY':
      if (
        state.imageryHistory &&
        state.imageryIdx !== undefined &&
        state.imageryHistory.length > state.imageryIdx + 1
      ) {
        return {
          ...state,
          currentBaseMap: state.imageryHistory[state.imageryIdx + 1],
          imageryIdx: state.imageryIdx + 1,
        };
      }
      return state;
    default:
      return state;
  }
};

export type CustomerContextDispatcher = React.Dispatch<
  React.ReducerAction<React.Reducer<ContextState, ContextActions>>
>;
export type Context = {
  state: ContextState;
  dispatcher: CustomerContextDispatcher;
};

const CustomerContext = React.createContext<Context | undefined>(undefined);

// eslint-disable-next-line react/prop-types
const CustomerContextProvider: React.FC<Context> = ({ children, ...context }) => (
  <CustomerContext.Provider value={context}>{children}</CustomerContext.Provider>
);

const useContext = (): Context => {
  const context = React.useContext(CustomerContext) as Context;
  if (context == null) {
    throw new Error('useContext must be used within CustomerContextProvider');
  }
  return context;
};

export { CustomerContextProvider, customerContextReducer, useContext };
