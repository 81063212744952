import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { UnexpectedError } from '../error-pages/UnexpectedError';
import { useApiLogger } from './ConsoleLogger';

const GlobalErrorHandler: React.FC = ({ children }) => {
  const apiLogger = useApiLogger();
  useEffect(() => {
    const callback = (e: ErrorEvent) => apiLogger.error(e.error);
    window.addEventListener('error', callback);
    return () => {
      window.removeEventListener('error', callback);
    };
  }, [apiLogger]);

  useEffect(() => {
    const callback = (e: PromiseRejectionEvent) => apiLogger.error(e.reason);
    window.addEventListener('unhandledrejection', callback);
    return () => {
      window.removeEventListener('unhandledrejection', callback);
    };
  }, [apiLogger]);

  return (
    <ErrorBoundary fallback={<UnexpectedError />} onError={(e) => apiLogger.error(e)}>
      {children}
    </ErrorBoundary>
  );
};

export default GlobalErrorHandler;
