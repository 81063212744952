import React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uuid from 'uuid';

import { faEdit } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { ProfileBubble } from '../customer/comments/ProfileBubble';
import { useCompanyUsers } from './useCompanyUsers';

import { useUserMe } from '../contexts/remote-data/useUserMe';
import './AssigneeDropdown.scss';
import { CompanyUserResponse } from '../apis/generated';
import { useCurrentCompanyIdUltimate } from '../contexts/remote-data/useCurrentCompanyIdUltimate';

export const EditAssigneeDropdown: React.FC<{
  assignee?: number;
  setAssignee: (assignee: number | undefined) => void;
  visibleEditButton?: boolean;
  showEmail?: boolean;
}> = ({ assignee, setAssignee, visibleEditButton, showEmail = false }) => {
  const [searchString, setSearchString] = React.useState<string>('');

  const companyId = useCurrentCompanyIdUltimate();

  const { users } = useCompanyUsers(companyId);

  const filterFun = (u: CompanyUserResponse | { user_id: undefined; email: undefined }) =>
    searchString.length > 0 ? u.email?.toLowerCase().includes(searchString.toLowerCase()) : true;

  const userListFiltered = users?.filter(filterFun) ?? [];

  const { userMe } = useUserMe();

  return (
    <div className="d-flex">
      <Dropdown>
        <Dropdown.Toggle
          id="dropdown-basic"
          variant="link"
          className="w-100 d-flex p-0 gap-2 hidden-dropdown justify-content-center border-0 text-decoration-none"
        >
          <ProfileBubble email={users?.find((user) => user.user_id === assignee)?.email} />
          {showEmail && (
            <span className="text-light">
              {users?.find((user) => user.user_id === assignee)?.email || 'Unassigned'}
            </span>
          )}

          <FontAwesomeIcon
            icon={faEdit}
            size="sm"
            style={{
              visibility: visibleEditButton ? 'visible' : 'hidden',
            }}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ zIndex: 1050 }}>
          <div className="px-3 pt-2 pb-3">
            <Form.Control
              onBlur={() => setTimeout(() => setSearchString(''), 1000)}
              type="text"
              className="w-100 rounded p-1"
              autoFocus
              placeholder="Search"
              value={searchString}
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
            />
          </div>

          <div style={{ width: '25em', height: '25em' }} className="overflow-y-scroll">
            {[
              ...[
                assignee !== userMe?.user_id && users?.find((user) => user.user_id === assignee),
                users?.find((user) => user.user_id === userMe?.user_id),
                {
                  user_id: undefined,
                  email: undefined,
                },
              ].filter((u) => u !== false && u !== undefined && filterFun(u)),
              ..._.sortBy(
                [
                  ...userListFiltered.filter(
                    (u) => u.user_id !== assignee && u.user_id !== userMe?.user_id,
                  ),
                ],
                (u) =>
                  'z'.repeat(u.email.toLowerCase().indexOf(searchString.toLowerCase())) + u.email,
              ),
            ].map((user) => {
              return (
                user && (
                  <Dropdown.Item
                    key={uuid.v4()}
                    onClick={() => {
                      setAssignee(user.user_id);
                    }}
                    className={`d-flex align-items-center w-100 overflow-hidden gap-2 ${
                      user.user_id === assignee ? 'bg-primary' : ''
                    }`}
                  >
                    <ProfileBubble email={user.email} />
                    {user.email ?? 'Unassigned'}
                  </Dropdown.Item>
                )
              );
            })}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
